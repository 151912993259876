<template>
  <div class="vx-row no-gutter items-center justify-center">
    <div class="vx-col sm:w-5/6 md:w-2/3 lg:w-1/2 m-0">
      <vx-card class="login-card py-3 px-3">
        <div slot="no-body" class="">
          <div class="vx-col sm:w-full">
            <div class="px-6 pt-8 login-tabs-container">

              <div class="vx-card__title mb-4">
                <h4 class="mb-4">Recupera tu contraseña</h4>
                <p v-if="canChangePassword && !completed">Escribe tu nueva contraseña, la cual <strong>debe contener:</strong></p>

                <vs-alert v-if="completed" class="h-full mb-5" color="success">
                  <span>¡Tu contraseña ha sido actualizada exitosamente!</span>
                </vs-alert>

                <p v-if="completed">Para continuar, visita la página de inicio e ingresa tus nuevas credenciales.</p>

              </div>

              <vs-alert v-if="errorMssg" class="h-full mb-5" color="danger">
                <span>{{ errorMssg }}</span>
              </vs-alert>

              <div class="vx-row" v-if="canChangePassword && !completed">
                <div class="vx-col md:w-1/2 sm:w-full w-full">
                  <p class="mb-5">
                    <feather-icon :svgClasses="`${colorForCriteria(0)} h-4 w-4`" class="p-2" :icon="iconForCriteria(0)" />Una letra minúscula <br>
                    <feather-icon :svgClasses="`${colorForCriteria(1)} h-4 w-4`" class="p-2" :icon="iconForCriteria(1)" />Una letra mayúscula <br>
                    <feather-icon :svgClasses="`${colorForCriteria(2)} h-4 w-4`" class="p-2" :icon="iconForCriteria(2)" />Un número <br>
                  </p>
                </div>
                <div class="vx-col md:w-1/2 sm:w-full w-full">
                  <p class="mb-5">
                    <feather-icon :svgClasses="`${colorForCriteria(3)} h-4 w-4`" class="p-2" :icon="iconForCriteria(3)" />Un símbolo <br>
                    <feather-icon :svgClasses="`${colorForCriteria(4)} h-4 w-4`" class="p-2" :icon="iconForCriteria(4)" />8 caracteres mínimo <br>
                    <feather-icon :svgClasses="`${colorForCriteria(5)} h-4 w-4`" class="p-2" :icon="iconForCriteria(5)" />Sin letras ni números consecutivos <br>
                  </p>
                </div>
              </div>

              <div class="vx-row" v-if="canChangePassword && !completed">
                <div :class="colClass">
                  <label class="vs-input--label">Contraseña *</label>
                  <vx-input-group class="w-full">
                    <vs-input
                      v-model.lazy="model.password"
                      v-validate="requiredRules"
                      :danger="hasError('password')"
                      :danger-text="errorText('password')"
                      :success="passwordFieldValid"
                      :type="passInputType"
                      :placeholder="requiredPlaceholder"
                      name="password"/>

                    <template slot="append">
                      <div class="append-text btn-addon" style="height:40px;">
                        <vs-button color="dark" @click="togglePassInputType()" icon-pack="feather" :icon="passInputIcon"></vs-button>
                      </div>
                    </template>
                  </vx-input-group>
                </div>
                <div :class="colClass">
                  <label class="vs-input--label">Confirma la contraseña *</label>
                  <vx-input-group class="w-full">
                    <vs-input
                      onpaste="return false;"
                      v-model.lazy="model.confirmed_password"
                      v-validate="requiredRules"
                      :danger="hasError('confirmed_password')"
                      :danger-text="errorText('confirmed_password')"
                      :success="confirmPasswordFieldValid"
                      :type="confirmPassInputType"
                      :placeholder="requiredPlaceholder"
                      name="confirmed_password"/>

                    <template slot="append">
                      <div class="append-text btn-addon" style="height:40px;">
                        <vs-button color="dark" @click="toggleConfirmPassInputType()" icon-pack="feather" :icon="confirmPassInputIcon"></vs-button>
                      </div>
                    </template>
                  </vx-input-group>
                </div>
              </div>

              <vs-button v-if="canChangePassword && !completed"  @click="requestReset" color="primary" class="mr-5 mt-4">Recuperar contraseña</vs-button>

              <div class="mt-base mb-6">
                <div class="text-dark mb-4" v-if="!canChangePassword && !completed">
                  Si deseas solicitar una nueva liga de recuperación <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="backToRequestChange">Da clic aquí.</router-link></span>
                </div>
                <div class="text-dark" v-if="!completed">
                  ¿Deseas volver a la página de inicio? <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="backToLogin">Da clic aquí.</router-link></span>
                </div>
                <div class="text-dark" v-if="completed">
                  Ir a la <span><router-link class="bold ml-1 inline-link-primary text-dark" to="" @click.native="backToLogin">página de inicio.</router-link></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
const errorMssgDef = "Ha ocurrido un error, intente más tarde."
const textType = "text";
const iconEye = "icon-eye";
const iconEyeOff = "icon-eye-off";
export default {
  name: "PasswordChangePage",
  props: ['token'],
  data() {
    return {
      colClass: "vx-col md:w-1/2 sm:w-full w-full mb-5",
      requiredRules: 'required',
      requiredPlaceholder: '(Requerido)',
      errorMssg: null,
      passInputPassMode: true,
      confirmPassInputPassMode: true,
      isMounted: false,
      canChangePassword: false,
      completed: false,
      criteria: [false, false, false, false, false, false, false],
      model: {
        password: null,
        confirmed_password: null,
      }
    }
  },
  async mounted(){
    this.showLoading(true)
    await this.checkForToken();
    this.showLoading(false)
  },
  computed: {
    passInputType(){
      return this.passInputPassMode ? "password" : textType;
    },
    passInputIcon(){
      return this.passInputPassMode ? iconEye : iconEyeOff;
    },
    confirmPassInputType(){
      return this.confirmPassInputPassMode ? "password" : textType;
    },
    confirmPassInputIcon(){
      return this.confirmPassInputPassMode ? iconEye : iconEyeOff;
    },
    passwordFieldValid(){
      return this.testPassword(this.model.password);
    },
    confirmPasswordFieldValid(){
      return this.model.password === this.model.confirmed_password && this.model.confirmed_password !=  null;
    },
  },
  methods: {
    async checkForToken(){
      try {
        const payload = {token: this.$props.token};
        const res = await this.publicApiPost('/api/v2/password/checkRecoveryToken', payload);
        this.canChangePassword = true;
      }
      catch(error) {
        if(error.response && error.response.data && error.response.data.message){
          this.errorMssg = error.response.data.message;
        }
        else {
          this.errorMssg = errorMssgDef;
        }
      }
    },
    async requestReset(){
      let ok = await this.validateForm();
      if(ok){
        await this.doRequestChange();
      }
    },
    async doRequestChange(){
      this.showLoading(true)
      try {
        const payload = {
          new_password: this.model.password,
          token: this.$props.token,
        };
        const res = await this.publicApiPost('/api/auth/user/reactive-account', payload);
        this.completed = true;
      }
      catch(error) {
        if(error.response && error.response.data && error.response.data.message){
          this.errorMssg = error.response.data.message;
        }
        else {
          this.errorMssg = errorMssgDef;
        }
      }
      this.showLoading(false)
    },
    async validateForm(){
      this.errorMssg = null;
      let r1 = await this.$validator.validateAll();
      if(r1){
        return this.validatePassword();
      }
      return false;
    },
    colorForCriteria(idx){
      return this.criteria[idx] ? 'text-success' : 'text-danger';
    },
    iconForCriteria(idx){
      return this.criteria[idx] ? 'CheckCircleIcon' : 'CircleIcon';
    },
    completePasswordForm(){
      return this.model.password != null && this.model.password.length >= 8;
    },
    completeConfirmPassword(){
      return this.model.confirmed_password != null && this.model.confirmed_password.length >= 8;
    },
    validatePassword(){
      if(this.model.password !== this.model.confirmed_password){
        this.errorMssg = "Las contraseñas no coinciden";
      }
      else {
        if(this.testPassword(this.model.password)){
          return true;
        }
        else {
          this.errorMssg = "La contraseña no cumple los requerimientos";
        }
      }
      return false;
    },
    testPassword(pass){
      if(pass === null || pass === undefined){
        this.criteria[0] = false;
        this.criteria[1] = false;
        this.criteria[2] = false;
        this.criteria[3] = false;
        this.criteria[4] = false;
        this.criteria[5] = false;
        this.criteria[6] = false;
        return false;
      }
      //let pass = this.pw1
      const number = /.*[0-9].*/
      const lower = /.*[a-z].*/
      const upper = /.*[A-Z].*/
      const special = /.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/
      const consecutive = ['abcd', 'bcde', 'cdef', 'defg', 'efgh', 'fghi', 'ghij', 'hijk', 'ijkl', 'jklm', 'klmn', 'lmnñ', 'mnño', 'nñop', 'ñopq', 'opqr', 'pqrs', 'qrst', 'rstu', 'stuv', 'tuvw', 'uvwx', 'vwxy', 'wxyz',
        '0123', '1234', '2345', '3456', '4567', '5678', '6789', '7890', '8901', '9012', '2109', '1098', '0987', '9876', '8765', '7654', '6543', '5432', '4321', '3210',
        'aaaa', 'bbbb', 'cccc', 'dddd', 'eeee', 'ffff', 'gggg', 'hhhh', 'iiii', 'jjjj', 'kkkk', 'llll', 'mmmm', 'nnnn', 'ññññ', 'oooo', 'pppp', 'qqqq', 'rrrr', 'ssss', 'tttt', 'uuuu', 'vvvv', 'wwww', 'xxxx', 'yyyy', 'zzzz',
        '1111', '2222', '3333', '5555', '6666', '7777', '8888', '9999',
        '!!!!', '""""', '####', '$$$$', '%%%%', '&&&&', '((((', '))))', '****', '++++', ',,,,', '----', '....', '////', '::::', ';;;;', '<<<<', '====', '>>>>',
        '????', '@@@@', '[[[[', ']]]]', '^^^^', '____', '````', '{{{{', '||||', '}}}}', '~~~~']

      this.criteria[0] = lower.test(pass);
      this.criteria[1] = upper.test(pass);
      this.criteria[2] = number.test(pass);
      this.criteria[3] = special.test(pass);
      this.criteria[4] = pass.length > 7;
      let no_consecutive = true
      for(let i = 0; i < consecutive.length; i ++){
        if(pass.toLowerCase().includes(consecutive[i])){
          no_consecutive = false
        }
      }
      this.criteria[5] = no_consecutive

      return (this.criteria[0] && this.criteria[1] && this.criteria[2] && this.criteria[3] && this.criteria[4] && this.criteria[5]);
    },
    togglePassInputType(){
      this.passInputPassMode = !this.passInputPassMode;
    },
    toggleConfirmPassInputType(){
      this.confirmPassInputPassMode = !this.confirmPassInputPassMode;
    },
    hasError(val){
      return this.errors.has(val);
    },
    errorText(val){
      return this.errors.first(val);
    },
    isSuccess(val){
      let ff = this.fields[val];
      return (ff && ff.required && ff.valid);
    },
    async backToLogin(){
      await this.$router.replace({name: 'login'});
    },
    async backToRequestChange(){
      await this.$router.replace({name: 'recuperarPassword'});
    }
  }
}
</script>

<style scoped>

</style>